import { isInIframe } from "./base/utils/iframe.utils";
import { getValueOfKey } from "./base/utils/object.utils";
import { makeUrl } from "./base/utils/url.utils";

export const FEATURE_FLAGS = {
  DISABLE_DONATIONS: false,
  ENABLE_VOIP: false,
  SHOW_NEXT_RECURRING_DONATION_DATE: true,
}

export const IS_PROD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
if (IS_DEV) window.IS_DEV_MODE = IS_DEV;
let _SHOULD_LOG = {
  value: IS_DEV
};
export const SHOULD_LOG = () => _SHOULD_LOG.value;
if (localStorage.getItem('SHOULD_LOG') === '8dc01b0de0431cb7eced92277c1f04c7') {
  _SHOULD_LOG.value = true;
}
Object.freeze(_SHOULD_LOG);

export const isInCypressTestMode = Boolean(window.Cypress) || Boolean(window.top?.Cypress) || (isInIframe() && IS_DEV && getValueOfKey<any>('APP_INSTANCE_ID') !== '0');

declare global {
  interface Window {
    Cypress: object,
    IS_DEV_MODE: boolean,
  }
}

const LocalAPIOverrideStorageKey = 'LOCAL_API_OVERRIDE';

const API_URL_MAP = Object.freeze({
  "app.turn2me.ie": "https://turn2me-api-production-htroeoozwa-ew.a.run.app",
  "preflight.turn2me.ie": "https://preflight-api.turn2me.ie",
  "qa-app.turn2me.ie": "https://qa-api.turn2me.ie",
  "uat-app.turn2me.ie": "https://uat-api.turn2me.ie",

  localhost:
    localStorage.getItem(LocalAPIOverrideStorageKey) ??
    "https://turn2me-api-v2.test",
  default: "https://turn2me-api-v2.test",
});

const { hostname } = window.location;

export const API_HOST = isInCypressTestMode ? (
  process.env.REACT_APP_CYPRESS_API ?? API_URL_MAP['localhost']
) : (
  (getValueOfKey<any>(hostname, API_URL_MAP) || API_URL_MAP.default).replace(/\/+$/, "")
);

export const isLocalhost = hostname === 'localhost';
export const isOfficialSite = hostname === 'app.turn2me.ie';
export const isTrainingSite = hostname === 'training.turn2me.ie';

const SENTRY_ENV_MAP = Object.freeze({
  "app.turn2me.ie": "production",
  "preflight.turn2me.ie": "prefight",
  "qa-app.turn2me.ie": "qa",
  "uat-app.turn2me.ie": "uat",
  default: "development",
});

export const SENTRY_ENV = getValueOfKey<any>(hostname, SENTRY_ENV_MAP) || SENTRY_ENV_MAP.default;

if (IS_DEV) document.documentElement.classList.add('DEV');

export const enableFrontlineWorkersFeature = true;

const WP_URL_MAP = Object.freeze({
  'default': 'https://turn2me.ie',
})

export const WP_URL = (getValueOfKey<any>(hostname, WP_URL_MAP) || WP_URL_MAP.default).replace(/\/+$/, "");

export const getWordPressLink = (url?: string) => {
  return url ? makeUrl(WP_URL, url) : WP_URL;
}

export const replaceHostnameWithWordPressLinkInDevMode = (url: string) => {
  if (!url || IS_PROD) return url;
  return url.replace(/(https?:|)(^|\/\/)(.*?\/)/g, WP_URL + '/');
}

export const getWordPressAPIEndpoint = (url: string) => getWordPressLink(`/wp-json/wp/v2/${url}`);
